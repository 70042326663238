<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>Indicate what property each of the following measurements describe:</p>

      <v-simple-table>
        <thead>
          <tr>
            <th>Measurement</th>
            <th>Length</th>
            <th>Area</th>
            <th>Volume</th>
            <th>Mass</th>
            <th>Density</th>
            <th>Time</th>
            <th>Temperature</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><stemble-latex content="$5.6\,\text{ns}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$3.2\,\text{kg/L}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$0.88\,\text{pm}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$540\,\text{km}^2$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$173\,\text{K}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$2.31\,\text{mm}^3$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$23^{\circ}\text{C}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question186',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
    };
  },
};
</script>
